<template>
  <div class="goods">
    <div class="goods-add">
      <span class="page-title">商品列表</span>
      <el-button style="margin-left: 16px;" class="le-publish-btn" type="primary" size="small"
                 @click="$router.push({name: 'mkt.goods.entity'})">新增商品
      </el-button>
    </div>

    <div class="goods-filter">

      <div>
        <el-form label-width="100px">
          <el-col :span="6">
            <el-form-item label="商品搜索">
              <el-input size="small" v-model="params.name" placeholder="请输入商品名称/ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="售价范围">
              <el-col :span="11">
                <el-input size="small" v-model.number="params.linePriceLimit" type="number" placeholder="最低价格">
                  <span slot="append">元</span>
                </el-input>
              </el-col>
              <el-col :span="2" class="range">至</el-col>
              <el-col :span="11">
                <el-input size="small" v-model.number="params.linePriceUp"  type="number" placeholder="最高价格">
                  <span slot="append">元</span>
                </el-input>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="商品类别">
              <el-popover
                placement="bottom"
                trigger="hover"
              >
                <sort-select :select="params.sortUuid"
                             @select="(v) => {params.sortUuid = v.uuid, params.sortName = v.name}"></sort-select>
                <el-button type="primary" plain size="small"
                           style="width: 120px" slot="reference">{{params.sortName === ''? '选取分类':params.sortName}}
                </el-button>
              </el-popover>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item label="创建时间">
              <time-range @change="handleTimeChange"></time-range>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <div class="goods-filter-buttons">
        <el-button @click="getSearch" size="small" type="primary">筛选</el-button>
        <el-button type="text" size="small" @click="empty">清空筛选</el-button>
      </div>
    </div>

    <div class="goods-list">
      <el-tabs class="yk-tabs" v-model="activeName" type="card">
        <el-tab-pane :label="`全部(${tabCount.all})`" name="all">

        </el-tab-pane>
<!--        <el-tab-pane :label="`销售中(${tabCount.onsale})`" name="onsale">-->

<!--        </el-tab-pane>-->
<!--        <el-tab-pane :label="`已下架(${tabCount.nosale})`" name="nosale">-->

<!--        </el-tab-pane>-->
<!--        <el-tab-pane :label="`已售罄(${tabCount.soldout})`" name="soldout">-->

<!--        </el-tab-pane>-->
<!--        <el-tab-pane :label="`设备专用(${tabCount.drafts})`" name="devices">-->
<!--        </el-tab-pane>-->
      </el-tabs>
      <div class="goods-list-value">
        <good-all :params="params" @total="(total) => {tabCount.all = total}"></good-all>
      </div>
    </div>
  </div>
</template>


<script type="text/javascript">
  import GoodAll from '@/components/GoodAll'
  import SortSelect from "./components/select";

  export default {
    components: {
      GoodAll,
      SortSelect
    },
    data() {
      return {
        load: {
          show: false,
          type: 1
        },
        params: {
          name: '',
          linePriceLimit: 0,
          linePriceUp: 0,
          sortUuid: '',
          sortName: '',
          startAt: 0,
          endAt: 0,
        },
        activeName: 'all',
        options: [],
        value1: '',
        value2: '',
        keyword: {
          tab_key: 'all', // 全部all  销售中onsale   下架中nosale  售罄soldout  回收站recycle
          search: '',
          group: [],
          price_start: '',
          price_end: '',
          time_start: '',
          time_end: '',
          sort: {}
        },
        time_start: '',
        time_end: '',
        tabCount: {
          all: 0,
          onsale: 0,
          nosale: 0,
          soldout: 0,
          drafts: 0,
          recycle: 0
        },

      }
    },
    async mounted() {
    },
    methods: {
      router: function (path) {
        this.$router.push({
          path: path
        });
      },
      getSearch() {

      },
      empty() {
        this.params = {
          name: '',
          linePriceLimit: 0,
          linePriceUp: 0,
          sortUuid: '',
          sortName: '',
          startAt: 0,
          endAt: 0,
        }
      },
      handleTimeChange(tr) {
        this.params.startAt = tr[0]
        this.params.endAt = tr[1]
      },

      oninput: function (value) {
        // return floatNum(value);
        return value
      }
    }
  };

</script>
<style lang="scss">
  .goods {
    @include container();

    &-add {
      margin-bottom: $middle-space;
    }

    &-filter {
      @include container();
      padding-top: 32px;
      background: white;
      border-radius: 4px;

      margin-bottom: $middle-space;

      .range {
        line-height: 32px;
        margin-top: 4px;
        text-align: center;
      }

      .el-form-item {
        margin-bottom: 16px;
      }


      .el-input-group {
        vertical-align: middle;
      }

      .el-input__inner {
        line-height: 32px;
        height: 32px;
      }

      &-buttons {
        margin-left: $middle-space;
      }
    }

    &-list {
      .el-tabs__content {
        padding: 0 !important;
      }

      &-value {
        background: white;
        padding: $container-padding $middle-space;
      }
    }

  }
</style>
