<template>
  <div class="yk-goods">
    <!--    <div class="yk-goods__header">-->
    <!--      <el-checkbox :indeterminate="indeterminate" @change="allCheckEvent" class="le_goods__header-checkbox"-->
    <!--                   v-model="allCheck">当页全选-->
    <!--      </el-checkbox>-->
    <!--    </div>-->
    <el-table :data="goods"
              @sort-change="sortChange" class="le_goods__main" ref="goodsTable" row-key="id"
              :default-sort="{prop: 'createdAt', order: 'descending'}" style="width: 100%" v-loading="loading">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="商品描述" width="309">
        <template slot-scope="scope">
          <div class="flex">
            <img :src="scope.row.thumb" class="yk-goods-pic"/>
            <div class="yk-goods-info">
              <el-popover :content="scope.row.description" placement="top-start" trigger="hover" width="232">
                <div class="name" slot="reference">{{ scope.row.name }}</div>
              </el-popover>
              <div class="id">ID：{{ scope.row.uuid }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :sort-orders="['ascending', 'descending']"  sortable="custom"
                       label="商品价格" width="120" prop="linePrice">
        <template slot-scope="scope">
          <span class="le_goods__main-goodstitle">￥{{ scope.row.linePrice / 100}}</span>
        </template>
      </el-table-column>

      <el-table-column :sort-orders="['ascending', 'descending']" label="销量" prop="sales" sortable="custom">
        <template slot-scope="scope">
          <span class="le_goods__main-goodstitle">{{ scope.row.sales }}</span>
        </template>
      </el-table-column>
      <el-table-column :sort-orders="['ascending', 'descending']" label="库存" prop="stocks" sortable="custom">
        <template slot-scope="scope">
          <span class="le_goods__main-goodstitle">{{ scope.row.stocks }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品状态" prop="status">
        <template slot-scope="scope">
          <template v-if="scope.row.stocks > 0">
            <el-tag size="medium" type="success">销售中</el-tag>
            <!--            <el-tag size="medium" type="info">下架中</el-tag>-->
          </template>
          <el-tag size="medium" type="warning" v-else>售罄</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createdAt" sortable="custom" fixed="right" width="144">
        <template slot-scope="scope">
                    <span
                      class="yk-goods-time">{{ new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="80">
        <div slot-scope="scope">
          <el-button type="text" size="small" @click="handleDetail(scope.row)">编辑信息</el-button>
          <el-button type="text" size="small" class="danger" @click="handleDelete(scope.row)">移除</el-button>

        </div>
      </el-table-column>
    </el-table>
    <el-pagination class="pagination" size="small" @current-change="handleCurrentChange"
                   :current-page="pagination.curPage"
                   :page-size="pagination.limit"
                   layout="total, prev, pager, next"
                   :total="pagination.total"/>
  </div>
</template>
<script type="text/javascript">

  export default {
    components: {},
    props: {
      params: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data() {
      return {
        goods: [],
        loading: false,
        pagination: {}
      };
    },
    watch: {
      params: {
        handler: function (v) {
          let p = {...v}
          p.linePriceLimit *= 100
          p.linePriceUp *= 100
          this.loadGoods(p)
        },
        deep: true,
      }
    },

    mounted() {
      this.loadGoods(this.params)
    },
    methods: {

      sortChange(val) {
        this.pagination.orderCreatedAt = 0
        this.pagination.orderSales = 0
        this.pagination.orderLinePrice = 0
        this.pagination.orderStocks = 0
        let name = val.prop.firstUpperCase()
        this.pagination['order' + name] = val.order ? (val.order === 'descending' ? 1 : 2) : 0
        this.handleCurrentChange(1)

      },
      loadGoods(pagination) {
        this.pagination = {
          ...pagination, curPage: 0,
          total: 0,
          start: 0,
          limit: 10,
          orderCreatedAt: 2,
          orderSales: 0,
          orderLinePrice: 0,
          orderStocks: 0,
        }
        this.fetchData()
      },

      fetchData() {
        let vue = this
        this.loading = true
        this.$api('mkt.goods.union', this.pagination).then(res => {
          vue.goods = res.list || []
          vue.pagination.total = parseInt(res.total)
          this.$emit('total', vue.pagination.total)
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
      handleDelete(row) {
        let vue = this

        this.$confirm(`删除[${row.name}]将同步移除门店中商品, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          vue.$api('mkt.goods.delete',{uuid: row.uuid}).then(() => {
            vue.$message.success('删除成功')
            vue.fetchData()
          })
        })
      },

      handleDetail(row) {
        this.$router.push({name: 'mkt.goods.entity', params: {uuid: row.uuid}})
      },


      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.loading = true
        this.pagination.start = (value - 1) * this.pagination.limit
        this.$api('mkt.goods.list', this.pagination).then(res => {
          this.goods = res || []
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
    }
  };
</script>
<style lang="scss" scoped="true">
  .yk-goods {
    &__header {
      margin-bottom: $middle-space;
    }

    &-pic {
      width: 80px;
      height: 80px;
      margin-right: 9px;
      display: block;
      -o-object-fit: contain;
      object-fit: contain;
    }

    .danger{
      color: $remark-text-color;
    }

    &-info {
      .name {
        font-family: PingFang SC;
        font-weight: 500;
        color: $color-black;
        opacity: .85;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        cursor: pointer;
        position: relative;
      }

      .id {
        @include font-little();
        color: $gray-7;
      }
    }

    &-time {
      @include font-little();
      color: $gray-7;
    }
  }
</style>
